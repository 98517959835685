import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Products from "../components/Products/products"

const Advanced = (props) => (
  <Layout location={props.location} title="Advanced">
    <SEO title="Advanced Example" />
    <h1>Jackalope Products Example</h1>
    <Products />
  </Layout>
)
export default Advanced